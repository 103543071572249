<template>
  <div class="my-second card bgcolor">
      <Head :title="$t('info.card_num')" :show="true"  ></Head>
      <div class="common-box card-box">
          <div class="card-main">
              <div class="card-list">
                   <div v-for="(l,i) in cardList" :key="i" class="card-item"   @click.stop="gotoEidtCard(l)">
                       <div class="img-box">
                           <img :src="l.bankCategory.bg_img" alt="">
                       </div>
                       <van-swipe-cell class="my-cell">
                            <div class="my-cell-box">
                                <div class="card-item-top" >
                                    <img :src="l.bankCategory.image" alt="">
                                    <div >
                                        <p>
                                            {{l.bankCategory.name}}
                                        </p>
                                        <p class="chuxu">
                                            {{$t('info.cu_card')}}
                                        </p>
                                    </div>
                                </div>
                                <div class="card-two">
                                    <div class="card-two-num">
                                        <p class="p1">{{$t('info.card_nums')}}</p>
                                        <p class="p2">{{l.banknum}}</p>
                                    </div>
                                    <div class="card-two-date">
                                        <p class="p1">{{$t('info.data_before')}}</p>
                                        <p class="p3">{{l.expired_at}}</p>
                                    </div>
                                </div>
                            </div>
                            <template #right>
                                <van-button  :text="$t('info.detele')" @click.stop="delOne(l)" class="delete-button" />
                            </template>
                       </van-swipe-cell>
                    </div>
              </div>
          </div>
          <van-button @click="gotowhere('mine-card-add')" class="confirm-btn">{{$t('info.add_card')}}</van-button>
      </div>
  </div>
</template>


<script>
import {mapState,mapMutations} from "vuex"
import {Dialog} from "vant"
export default {
    data(){
        return {
            list:[],
            arr:['bg-tai','bg-zhong','bg-pan'],
            item:{}
        }
    },
    computed:{
        ...mapState(['cardList']),
    },
    methods:{
        gotoEidtCard(item){
            this.$router.push({
                name:"mine-card-set",
                query:{
                    id:item.id
                }
            })
        },
        delOne(item){
            Dialog.confirm({
                title: '',
                message: this.$t('add.confirm_delete'),
            })
            .then(() => {
                // on confirm
                this.$ajax.delbank({
                    id:item.id
                }).then(res=>{
                    if(res.code==200){
                        var list = this.cardList.filter(val=>val.id!==item.id);
                        this.setCardList(list)
                    }
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        ...mapMutations(['setCardList']),
        gotowhere(name){
            this.$router.push({
                name
            })
        }
    },
    mounted(){
        this.$ajax.banklist()
        .then(res=>{
            if(res.code==200){
                this.list =res.data;
                this.setCardList(res.data)
            }
        })
    }
}
</script>
